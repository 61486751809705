$borderColor: lightgray;
$standardBorderStyle: 1px solid $borderColor;
$standardButtonHeight: 48px;
$standardButtonPadding: 4px 10px;
$standardBorderRadius: 2px;
$standardSpacing: 10px;
$extraSpacing: 20px;
$productBottomSpace: 20px;
$popupBorderRadius: 5px;
$headerHeight: 48px;
$footerHeight: 33px;
$footerButtonsHeight: 60px;
$standardSeparatorColor: #ececec;

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

.standard-heading {
  margin: 0;
  padding: 10px 0;
}

.centered-div {
  text-align: center;
}

.components-wide-container__root {
  width: 50rem;
}

.category-list__parent {
  font-size: 1.2em;
  font-weight: 500;
}

.category-list__parent .expander__text-container, .category-list__parent-name {
  min-width: 250px;
  display: inline-block;
}

.category-list__child-container {
  font-weight: normal;
  margin-left: 10px !important;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 10px 0;
  margin: 10px 0;
}

.category-list__child {
  font-size: 0.9em;
}

.category-list__child-name {
  min-width: 250px;
  display: inline-block;
}

.fade-in {
  opacity: 1;
  transition: all 250ms linear 0s;
}

.fade-out {
  opacity: 0;
  transition: all 250ms linear 0s;
}

textarea {
  width: 100%;
}

$progressBarColor: lightgray;
$progressColor: rgb(0 114 255);

progress {
  border: none;
  width: 100%;
  height: 17px;
  background: $progressBarColor;
  color: $progressBarColor;
}

.progress-text {
  position: absolute;
  color: white;
  left: 50%;
  transform: translate(-50%);
}

progress::-webkit-progress-value, progress::-webkit-progress-value {
  background: $progressColor;
}

progress::-moz-progress-bar, progress::-webkit-progress-bar {
  background: $progressBarColor;
}

.loading-component__container {
  position: fixed;
  top: calc(45% - 25px); /* 25px = half of element width */
  left: calc(50% - 25px);
}

.loading-component__indicator {
  border: 8px solid #f3f3f3;
  border-top: 8px solid black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.loading-component__text {
  margin-top: $standardSpacing;
}

.mini-loading-component__container {
  display: inline-block;
  vertical-align: bottom;
}

$miniLoaderSize: 25px;
.mini-loading-component__indicator {
  border: 8px solid gray;
  border-top: 8px solid black;
  border-radius: 50%;
  width: $miniLoaderSize;
  height: $miniLoaderSize;
  animation: spin 2s linear infinite;
}

.mini-loading-component__text {
  margin-top: $standardSpacing;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.component-tabs__tab-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
}

.component-tabs__tab-item {
  margin-right: 10px;
  border: 1px solid black;
  border-radius: 2px;
  padding: 10px;
  cursor: pointer;
}

.component-tabs__tab-item-selected {
  background: black;
  color: white;
}

.component-tabs__component {
  display: none;
}

.component-tabs__component-visible {
  display: block;
}

.celery-box__textarea {
  overflow: auto;
}

.heading {
  font-size: 1.5em;
  margin: 0;
  margin-bottom: $standardSpacing;
}

.centered-text {
  text-align: center;
}

.centered-heading {
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#footer {
  width: 100%;
  background: black;
  color: white;
  text-align: center;
  padding: 6px 0;
  clear: both;
  margin-top: $standardSpacing;
}

.header-container {
  background-color: black !important;
  box-shadow: none !important;
}

.top-padding {
  padding-top: $standardSpacing;
  padding-bottom: $standardSpacing;
}

#footer {
  /*position: absolute;
  bottom: 0;
  left: 0;*/
}

.container {
  max-width: 1080px;
  width: 100%;
  padding-left: $standardSpacing;
  padding-right: $standardSpacing;
  margin: 0 auto;
}

.page-filler {
  padding-top: calc(#{$headerHeight} + #{$standardSpacing});
  min-height: calc(100vh - #{$footerHeight} - #{$standardSpacing}); /* Standard spacing is the margin-top footer has */
}

.button {
  border: $standardBorderStyle;
  height: $standardButtonHeight;
  padding: $standardButtonPadding;
  border-radius: $standardBorderRadius;
}
.subcategory-checklist {
  margin-left: 30px;
}

@media screen and (max-width: 599px) {
}

@media screen and (min-width: 600px) {
}

/* MOBILE, TABLET */
@media screen and (max-width: 1369px) {
  select,
  input {
    font-size: 16px;
  }

  #header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }

  #footer {
    margin-bottom: $footerButtonsHeight;
  }

  .button {
    width: 100%;
    height: 40px;
    font-size: 16px;
    cursor: pointer;
  }
}

/* DESKTOP */
@media screen and (min-width: 1370px) {
  .outer-container {
    padding: $standardSpacing;
  }
}
